import styled from "styled-components";
import { Box, BoxProps } from "../rebrand/core/box";

export const Badge = styled(Box).attrs<BoxProps>((props) => ({
  display: "inline",
  fontSize: 2,
  borderRadius: 2,
  alignSelf: "start",
  bg: "gray._0",
  px: props.px ?? 2,
  py: props.py ?? 2,
}))``;
