import styled from "styled-components";
import { mdxShortcodes, Shortcodes } from "../mdx-content";

export const StyledULWithCheckMarks = styled.ul`
  display: block;
  list-style-type: none;
  font-size: ${({ theme }) => theme.fontSizes[3]}
  margin: 16px 0;

  & > * {
    margin-bottom: 1em;
    padding-left: 52px;
    position: relative;

    // li item check mark
    &:before {
      position: absolute;
      top: 6px;
      left: 10px;
      z-index: 1;
      content: " ";
      display: inline-block;
      transform: rotate(45deg);
      height: 12px;
      width: 8px;
      border-bottom: 2px solid ${({ theme }) => theme.colors.icon.loud};
      border-right: 2px solid ${({ theme }) => theme.colors.icon.loud};
    }

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: " ";
      display: inline-block;
      background-color: ${({ theme }) => theme.colors.background.default};
      border-radius: 50%;
      height: 28px;
      width: 28px;
    }
  }
`;

export const resourceShortcodes: Shortcodes = {
  ...mdxShortcodes,
  ul: StyledULWithCheckMarks,
};
