import React from "react";
import { PropsWithChildren } from "react";
import { Box, BoxProps } from "../box";

export type PaperProps = BoxProps;

export function Paper(props: PropsWithChildren<PaperProps>) {
  const { children, ...otherProps } = props;

  return (
    <Box
      paddingSpacing={[0, 0, 5]}
      paddingTopSpacing={[3.75, 3.75, 5]}
      borderRadius={3}
      boxShadow={[0, 0, 4]}
      backgroundColor="background.base"
      {...otherProps}
    >
      {children}
    </Box>
  );
}
