import styled from "styled-components";
import { Box } from "../rebrand/core/box";

export const Blockquote = styled(Box).attrs(() => ({
  as: "blockquote",
  px: 6,
  my: 6,
  lineHeight: 4,
}))`
  border-left: 2px solid ${({ theme }) => theme.colors.gray._200};
`;
