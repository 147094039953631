import React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";
import { Form } from "../../components/forms";
import { Layout } from "../../components/layout";
import MdxContent from "../../components/mdx-content";
import { Box } from "../../components/rebrand/core/box";
import { Grid } from "../../components/rebrand/core/Grid/Grid";
import { Paper } from "../../components/rebrand/core/Paper/Paper";
import { Typography } from "../../components/rebrand/core/Typography/Typography";
import { Section } from "../../components/rebrand/sections/Section/Section";
import { PageHeaderSection } from "../../components/rebrand/sections/PageHeader/PageHeaderSection"; 
import { SEO } from "../../components/seo";
import { resourceShortcodes } from "../../components/shortcodes/resource";

const StyledLayout = styled(Layout)`
  background: linear-gradient(180deg, rgb(255, 255, 255) 40%, rgb(243, 244, 252) 82.21%);
`;

interface CareersPageProps extends PageProps {
  data: Queries.CareersPageQuery;
}

export const pageQuery = graphql`
  query CareersPage {
    site {
      siteMetadata {
        pages {
          careers {
            title
            description
            keywords
          }
        }
      }
    }
  }
`;

export default function Careers(props: CareersPageProps) {

  const {
    location: { pathname },
    data:  { site }
  } = props;

  const cta = {
      caption : '¿Estás en busqueda de nuevos horizontes?',
      title : 'Únete al equipo Polarycs', 
      subtitle : '¿No encuentras ofertas disponibles? Por favor envíanos tu informacióm y se parte de futuros procesos de selección, tanto para ofertas laborales como para prácticas profesionales. Agradecemos tu interés por ser parte de Polarycs.',
      button : {
          href : '#',
          text : 'text'
      }
  };

  const { title, description, keywords } = site?.siteMetadata?.pages?.careers as Queries.SiteSiteMetadataPagesCareers;

  const seo = {
    title: title as string,
    description: description as string,
    // keywords: keywords as [],
    pathname,
  };

  let contentColWidthMd = 6;

  let FeaturedContent = (
  <>
      {/* Image */}
      <Box
        gridColumn={["3 / span 8", "4 / span 6", "2 / span 4"]}
        marginLeftSpacing="-11%"
        gridRow={1}
        paddingYSpacing={[6, 6, 12.5]}
      >
        {/* <Image data={featuredImage} alt="Resource Image" /> */}
      </Box>
      <Box
        gridColumn={["1 / span 12", "1 / span 12", "7 / span 5"]}
        gridRow={[2, 2, "1 / span 2"]}
        paddingTopSpacing={[0, 0, 10]}
        paddingBottomSpacing={[0, 0, 3]}
      >
        <Paper
            position={["static", "static", "sticky"]}
            top="7rem"
            backgroundColor={["transparent", "transparent", "background.base"]}
        >
          {cta.caption && (
          <Typography variant="label2" color="text.blue" marginBottomSpacing={1.5}>
              {cta.caption}
          </Typography>
          )}
          <Typography variant="h3" marginBottomSpacing={1.5}>
            {cta.title}
          </Typography>
          <Typography variant="body2" color="text.muted" marginBottomSpacing={2}>
            {cta.subtitle}
          </Typography>
          <Form type="careers" gridGap="14px" />
        </Paper>
      </Box>
  </>
  );
  return (
    <StyledLayout>
      <SEO {...seo} />
      <PageHeaderSection title={"Súmate hoy a nuestro equipo"} subtitle={"Conoce hoy nuestras ofertas laborales abiertas y reunámonos"}/>
      <Section
        paddingTopSpacing={[10, 10]}
        marginBottomSpacing={[-10, -10]}
        backgroundColor="transparent"
        overflow="visible"
      >
        <Grid gridTemplateColumns="repeat(12, 1fr)">
          <Box gridColumn={["1 / span 12"]} gridRow={1} backgroundColor="background.faint" borderRadius={4} />
          {FeaturedContent}
          <Box
            gridColumn={["1 / span 12", "1 / span 12", `1 / span ${contentColWidthMd}`]}
            paddingLeftSpacing={[0, 0, 6]}
          >
            <MdxContent shortcodes={resourceShortcodes}>
              Listado de ofertas
            </MdxContent>
          </Box>
        </Grid>
      </Section>
    </StyledLayout>
  );
}
