/* eslint-disable react/display-name */
import React from "react";
import { MDXProvider } from "@mdx-js/react";
import styled from "styled-components";
import { Badge } from "./core/badge";
import { Blockquote } from "./core/blockquote";
import { Box } from "./rebrand/core/box";

const StyledUL = styled.ul`
  display: block;
  list-style-type: disc;
  font-size: ${({ theme }) => theme.fontSizes[3]};

  margin: 16px 0;
  padding-left: 24px;

  & > * {
    margin-bottom: 1em;
  }
`;

const StyledOL = styled.ol`
  display: block;
  list-style-type: decimal;
  font-size: ${({ theme }) => theme.fontSizes[3]};

  margin: 16px 0;
  padding-left: 24px;

  & > * {
    margin-bottom: 1em;
  }
`;

export type Shortcodes = {
  [key: string]: (props: Record<string, unknown>) => JSX.Element;
};

export const mdxShortcodes: Shortcodes = {
  h1: (props) => <Box as="h2" marginTop={12} {...props} />,
  h2: (props) => <Box as="h4" marginTop={8} {...props} />,
  h3: (props) => <Box as="h4" marginTop={8} {...props} />,
  h4: (props) => <Box as="h4" marginTop={8} {...props} />,
  h5: (props) => <Box as="h4" marginTop={8} {...props} />,
  h6: (props) => <Box as="h4" marginTop={8} {...props} />,
  blockquote: Blockquote,
  ul: StyledUL,
  ol: StyledOL,
  p: (props) => <Box {...props} as="p" marginTop={4} marginBottom={6} textStyle="lg" />,
  img: (props) => <Box as="img" marginTop={4} marginBottom={4} width="100%" {...props} />,
  figcaption: (props) => (
    <Box as="figcaption" paddingTop={2} textAlign="center" fontSize={1} lineHeight={1} {...props} />
  ),
  inlineCode: (props) => <Badge paddingY={1} {...props} />,
};

interface MdxContentProps {
  shortcodes?: Shortcodes;
  children?: React.ReactNode;
}

export default function MdxContent(props: MdxContentProps) {
  const { shortcodes, children } = props;
  console.log(mdxShortcodes)

  return (
    <MDXProvider components={shortcodes ?? mdxShortcodes}>
      <div data-sb-field-path="markdown_content" style={{ padding: 0, margin: 0 }}>
        {children}
      </div>
    </MDXProvider>
  );
}
